import React from 'react';
import { Link } from "gatsby";

import CtaShape2 from "../../assets/images/cta-shape2.png";
import Circle from "../../assets/images/circle.png";
import CtaShape from "../../assets/images/cta-shape.png";

const Cta = () => {
    return (
        <div className="iot-cta-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="cta-iot-img">
                            <img 
                                src={CtaShape2}
                                className="animate__animated animate__fadeInUp animate__delay-0.6s" 
                                alt="image" 
                            />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="cta-iot-content">
                            <h3>Σ | SOLUTORIA</h3>
                            <p>Te apoyamos complementando <b>la mejora de tus procesos de negocio</b>, a través de la <b>personalización</b> de nuestras soluciones base a las <b>necesidades especificas de tu negocio</b>.</p>

                            <Link to="/contact" className="btn btn-primary">
                                Comencemos !
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-box">
                <img src={Circle} alt="image" />
            </div>
            <div className="cta-shape">
                <img src={CtaShape} alt="image" />
            </div>
        </div>
    );
}

export default Cta;
