import React from 'react';

import Icon1 from "../../assets/images/icon1.png";
import Icon2 from "../../assets/images/icon2.png";
import Icon3 from "../../assets/images/icon3.png";

const WhyWeAreBest = () => {
    return (
        <div className="iot-why-choose-us pt-80">
            <div className="container">
                <div className="section-title">
                    <h2><b>Asesoramos</b> respecto a temas donde somos <b>| Σ | xpertos</b></h2>
                    <div className="bar"></div>
                    <p>Nuestra experiencia en la industria financiera en <b>Bancos, AFP, Compañías de Seguros, Corredoras de Bolsa y AGF</b>, nos permite ofrecer servicios integrales a nuestros clientes (Asesoría + Herramientas)</p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-iot-box">
                            <div className="icon">
                                <img src={Icon1} alt="image" />
                            </div>
                            <h3>Soluciones de Software</h3>
                            <p>Contamos con soluciones <strong>listas para usar</strong> orientadas a necesidades de empresas de <strong>servicios financieros</strong> y sus <strong>clientes finales</strong>, con foco en Corredoras de Bolsa, Compañías de Seguros, Isapres y Administradoras Generales de Fondos.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-iot-box">
                            <div className="icon">
                                <img src={Icon2} alt="image" />
                            </div>
                            <h3>Asesorías en gestión de clientes</h3>
                            <p>Tenemos experiencia en implementación de modelos de <strong>gestión de clientes</strong> orientados a identificar los deseos y necesidades reales de los clientes, y mejorar su experiencia a través de metodologias probadas como <strong>VoC</strong>, <strong>NPS</strong> y <strong>Diseño de Servicios</strong>.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-iot-box">
                            <div className="icon">
                                <img src={Icon3} alt="image" />
                            </div>
                            <h3>Asesorías empresariales</h3>
                            <p>Ofrecemos servicios de asesoría orientada a la <strong>industria financiera</strong>, en temas de gestión de <strong>procesos operacionales, control de inversiones, medios de pago, certificados Tributarios, FATCA y APV</strong> entre otros, fundados en profesionales con más de 10 años de experiencia.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyWeAreBest;
