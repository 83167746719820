import React from 'react';

import dotnet from '../../assets/images/technologies/dotnet.png'
import flutter from '../../assets/images/technologies/flutter.png'
import html5 from '../../assets/images/technologies/html5.png'
import ionic from '../../assets/images/technologies/ionic.png'
import java from '../../assets/images/technologies/java.png'
import javascript from '../../assets/images/technologies/javascript.png'
import jquery from '../../assets/images/technologies/jquery.png'
import mysql from '../../assets/images/technologies/mysql.png'
import netcore from '../../assets/images/technologies/netcore.png'
import nodejs from '../../assets/images/technologies/nodejs.png'
import php from '../../assets/images/technologies/php.png'
import postgresql from '../../assets/images/technologies/postgresql.png'
import python from '../../assets/images/technologies/python.png'
import react from '../../assets/images/technologies/react.png'
import sqlserver from '../../assets/images/technologies/sqlserver.png'
import uipath from '../../assets/images/technologies/uipath.png'


import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 3000,
    responsive: {
        0:{
            items:2,
        },
        768:{
            items:6,
        },
        1200:{
            items:6,
        }
    }
}

const PartnerStyleTwo = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="repair-partner-area bg-f9fafb">
            <div className="container">
                {display ? <OwlCarousel 
                    className="repair-partner-slides owl-carousel owl-theme"
                    {...options}
                >
                    <div className="single-repair-partner">
                        <a href="https://dotnet.microsoft.com/en-us/" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={dotnet} alt="dotnet" />
                            <img height={60} width={120} src={dotnet} alt="dotnet" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://flutter.dev/" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={flutter} alt="flutter" />
                            <img height={60} width={120} src={flutter} alt="flutter" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://developer.mozilla.org/es/docs/Glossary/HTML5" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={html5} alt="html5" />
                            <img height={60} width={120} src={html5} alt="html5" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://ionicframework.com/" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={ionic} alt="ionic" />
                            <img height={60} width={120} src={ionic} alt="ionic" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://www.oracle.com/cl/java/" target="_blank" rel="noreferrer">
                            <img height={70} width={130} src={java} alt="java" />
                            <img height={70} width={130} src={java} alt="java" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://www.javascript.com/" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={javascript} alt="javascript" />
                            <img height={60} width={120} src={javascript} alt="javascript" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://jquery.com/" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={jquery} alt="jquery" />
                            <img height={60} width={120} src={jquery} alt="jquery" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://www.mysql.com/" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={mysql} alt="mysql" />
                            <img height={60} width={120} src={mysql} alt="mysql" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://docs.microsoft.com/en-us/aspnet/core/?view=aspnetcore-6.0" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={netcore} alt="netcore" />
                            <img height={60} width={120} src={netcore} alt="netcore" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://nodejs.org/es/" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={nodejs} alt="nodejs" />
                            <img height={60} width={120} src={nodejs} alt="nodejs" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://www.php.net/" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={php} alt="php" />
                            <img height={60} width={120} src={php} alt="php" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://www.postgresql.org/" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={postgresql} alt="postgresql" />
                            <img height={60} width={120} src={postgresql} alt="postgresql" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://www.python.org/" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={python} alt="python" />
                            <img height={60} width={120} src={python} alt="python" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://es.reactjs.org/" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={react} alt="react" />
                            <img height={60} width={120} src={react} alt="react" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://www.microsoft.com/es-es/sql-server/sql-server-downloads" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={sqlserver} alt="sqlserver" />
                            <img height={60} width={120} src={sqlserver} alt="sqlserver" />
                        </a>
                    </div>
                    
                    <div className="single-repair-partner">
                        <a href="https://www.uipath.com/" target="_blank" rel="noreferrer">
                            <img height={60} width={120} src={uipath} alt="uipath" />
                            <img height={60} width={120} src={uipath} alt="uipath" />
                        </a>
                    </div>

                </OwlCarousel> : ''}
            </div>
        </div>
    );
}

export default PartnerStyleTwo;
