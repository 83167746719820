import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

const OurServices = () => {
    return (
        <div className="iot-services-area pt-80 pb-50">
            <div className="container justify-content-center">
                <div className="section-title">
                    <h2>Nuestros productos y servicios</h2>
                    <div className="bar"></div>
                    <p>tomamos en serio nuestro trabajo</p>
                </div>
                
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="soluto-products bg1">
                            <div className="icon">
                                <i className="flaticon-dashboard"></i>
                            </div>

                            <h3>SoluTicket</h3>
                            <h3>Gestión de Requerimientos</h3>
                            <h3><strong>Σ</strong> PRODUCTIVIDAD</h3>
                            <p>Gestión de requerimientos entre las distintas áreas de una empresa y sus proveedores, con medición de SLA's.</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="soluto-products bg2">
                            <div className="icon">
                                <i className="flaticon-software"></i>
                            </div>

                            <h3>Workflow</h3>
                            <h3>Suscripción Seguros</h3>
                            <h3><strong>Σ</strong> COMERCIAL - OPERACIONAL</h3>
                            <p>Gestión ajustada a los procesos internos de cada compañia en cada paso de la suscripción de un seguro, considerando ciclos de validación y monitoreo en linea desde la propuesta hasta la emisión de la poliza.</p>
                            <p>&nbsp;</p>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="soluto-products bg3">
                            <div className="icon">
                                <i className="flaticon-skyline"></i>
                            </div>

                            <h3>SoluFinance</h3>
                            <h3>&nbsp;</h3>
                            <h3><strong>Σ</strong> AHORRO INVERSIONES PENSIONES</h3>
                            <p>¿Tu patrimonio está correctamente invertido?, ¿Estas asumiendo más riesgo del necesario?, ¿Podrías obtener una mejor rentabilidad?. Respondemos esta y otras preguntas a través de nuestro modelo de asesoría financiera, para entregar como resultado un plan de inversiones personalizado.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="soluto-products bg4">
                            <div className="icon">
                                <i className="flaticon-support"></i>
                            </div>

                            <h3>Consultas y Reclamos</h3>
                            <h3>Seguros C2131 SVS</h3>
                            <h3><strong>Σ</strong> COMPAÑÍAS DE SEGUROS</h3>
                            <p>Solución que permite gestionar la atención y respuestas a clientes, de forma oportuna y completa por las compañías de seguros.</p>
                            <p>&nbsp;</p>
                     
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="soluto-products bg5">
                            <div className="icon">
                                <i className="flaticon-data"></i>
                            </div>

                            <h3>Gestión Proceso Siniestros</h3>
                            <h3>&nbsp;</h3>
                            <h3><strong>Σ</strong> COMPAÑÍAS DE SEGUROS</h3>
                            <p>Solución que permite gestionar el flujo de siniestros desde su denuncio hasta la liquidación, considerando consulta de estados y otras características para asegurados.</p>
                            <br></br>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="soluto-products bg6">
                            <div className="icon">
                                <i className="flaticon-monitor"></i>
                            </div>

                            <h3>SoluTax</h3>
                            <h3>&nbsp;</h3>
                            <h3><strong>Σ</strong> SERVICIOS FINANCIEROS</h3>
                            <p>SoluTax una vez conectado a los sistemas de su compañía, se hace cargo de la generación de declaraciones juradas y certificados tributarios a clientes, considerando todas las modificaciones normativas anuales del SII.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="soluto-products bg7">
                            <div className="icon">
                                <i className="flaticon-repair"></i>
                            </div>

                            <h3>SoluTest</h3>
                            <h3>&nbsp;</h3>
                            <h3><strong>Σ</strong> QA SOFTWARE</h3>
                            <p>Gestion de testing de software, con administrador de planes de prueba, su ejecución, y metricas de calidad de proveedores.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                            <p>&nbsp;</p>
                            <br></br>
                     
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="soluto-products bg8">
                            <div className="icon">
                                <i className="flaticon-idea"></i>
                            </div>

                            <h3>SoluMinutas</h3>
                            <h3>&nbsp;</h3>
                            <h3><strong>Σ</strong> PRODUCTIVIDAD</h3>
                            <p>Soluminutas permite administrar, registrar y hacer seguimiento de tus minutas, proyectos y comites, de forma centralizada y facil, incorporando variadas metricas como numero de horas utilizadas en reuniones, y cumplimiento de tareas y compromisos de sus participantes.</p>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="soluto-products bg9">
                            <div className="icon">
                                <i className="flaticon-factory"></i>
                            </div>

                            <h3>Gestión Normativa</h3>
                            <h3>&nbsp;</h3>
                            <h3><strong>Σ</strong> SERVICIOS FINANCIEROS</h3>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="soluto-products bg10">
                            <div className="icon">
                                <i className="flaticon-data-1"></i>
                            </div>

                            <h3>SoluDocs</h3>
                            <h3>&nbsp;</h3>
                            <h3><strong>Σ</strong> PRODUCTIVIDAD</h3>
                            <p>Gestión Documental.</p>
                            <p>&nbsp;</p>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="soluto-products bg11">
                            <div className="icon">
                                <i className="flaticon-smartphone"></i>
                            </div>

                            <h3>SoluVoC</h3>
                            <h3>La Voz de tus Clientes</h3>
                            <h3><strong>Σ</strong> PRODUCTIVIDAD</h3>
                            <p>¿Tienes un negocio y no sabes si tus clientes están satisfechos?, asegúrate de no perder clientes por no conocer su opinión.</p>
                       
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default OurServices;
