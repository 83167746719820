import React from 'react'

import ans from '../../assets/images/clients/ans.png'
import amcor from '../../assets/images/clients/amcor.png'
import colmenaseguros from '../../assets/images/clients/colmenaseguros.png'
import grahammiller from '../../assets/images/clients/grahammiller.png'
import contempora from '../../assets/images/clients/contempora.png'
import petra from '../../assets/images/clients/petra.jpg'
import facm from '../../assets/images/clients/facm.png'
import planvital from '../../assets/images/clients/planvital.png'
import afpcapital from '../../assets/images/clients/afpcapital.png'
import vendomatica from '../../assets/images/clients/vendomatica.png'
import sura from '../../assets/images/clients/sura.png'
import metlife from '../../assets/images/clients/metlife.png'
import unired from '../../assets/images/clients/unired.jpg'
import isalud from '../../assets/images/clients/isalud.png'



const Partner = () => {
    return (
        <>
            <br />
            <br />
            <br />
            <div className="ready-to-talk">
                <div className="container">
                    <h3>Nuestros Clientes</h3>
                    {/* <p>Our team is here to answer your question about Spet</p>
                    
                    <Link to="/contact" className="btn btn-primary">
                        Contact Us
                    </Link>

                    <span>
                        <a href="/" rel="noreferrer">Or, get started now with a free trial</a>
                    </span> */}
                </div>
            </div>

            <div className="partner-area partner-section">
                <div className="container">
                    {/* <h5>More that 1.5 million businesses and organizations use Spet</h5> */}

                    <div className="partner-inner">
                        <div className="row justify-content-center">

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={ans} alt="ans" />
                                    <img src={ans} alt="ans" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={amcor} alt="amcor" />
                                    <img src={amcor} alt="amcor" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={colmenaseguros} alt="colmenaseguros" />
                                    <img src={colmenaseguros} alt="colmenaseguros" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={grahammiller} alt="grahammiller" />
                                    <img src={grahammiller} alt="grahammiller" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={contempora} alt="contempora" />
                                    <img src={contempora} alt="contempora" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={petra} alt="petra" />
                                    <img src={petra} alt="petra" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={facm} alt="facm" />
                                    <img src={facm} alt="facm" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={planvital} alt="planvital" />
                                    <img src={planvital} alt="planvital" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={afpcapital} alt="afpcapital" />
                                    <img src={afpcapital} alt="afpcapital" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={vendomatica} alt="vendomatica" />
                                    <img src={vendomatica} alt="vendomatica" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={sura} alt="sura" />
                                    <img src={sura} alt="sura" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={metlife} alt="metlife" />
                                    <img src={metlife} alt="metlife" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={unired} alt="unired" />
                                    <img src={unired} alt="unired" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a>
                                    <img src={isalud} alt="isalud" />
                                    <img src={isalud} alt="isalud" />
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
 
}

export default Partner;  