import React from 'react';
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";

import MainBanner from '../components/IOT/MainBanner';
import OurServices from '../components/IOT/OurServices';
import Cta from '../components/IOT/Cta';
import WhyWeAreBest from '../components/IOT/WhyWeAreBest';
import Features from '../components/IOT/Features';


import PartnerStyleTwo from '../components/Common/PartnerStyleTwo';
import FunFactsArea from '../components/Common/FunFactsArea';
import Partner from "../components/Common/Partner";



const IndexPage = () => (
    <Layout>
        <SEO title="Σ | Solutoria | tecnología sin excusas" />
        <Navbar />
        <MainBanner />
        <PartnerStyleTwo />
        <OurServices />
        <Cta />
        <FunFactsArea />
        {/* <Features /> */}
        {/* <TeamStyleTwo /> */}
        <WhyWeAreBest />
        {/* <Feedback /> */}
        <Partner />
        {/* <BlogPost /> */}
        <Footer />
    </Layout>
)

export default IndexPage;
