import React from 'react';

import IOTMainImg1 from "../../assets/images/iot-banner-image/iot-main-img1.png";
import IOTShape1 from "../../assets/images/iot-banner-image/iot-shape1.png";

const MainBanner = () => {
    return (
        <div className="iot-main-banner">
            <div className="container">
                <div className="iot-banner-content">
                    <span>Tecnología sin excusas</span>
                    <h2>Σ | SOLUTORIA</h2>
                    <p>Te apoyamos complementando <strong>la mejora de tus procesos de negocio</strong>, a través de la <strong>personalización</strong> de nuestras soluciones base a las <strong>necesidades especificas de tu negocio</strong>.</p>
                    
                </div>

                <div className="iot-banner-image">
                    <img 
                        src={IOTMainImg1}
                        className="animate__animated animate__fadeInUp animate__delay-0.8s" 
                        alt="image"
                    />
                
                    <img 
                        src={IOTShape1}
                        className="animate__animated animate__zoomIn" 
                        alt="image"
                    />
                </div>

                <div className="animate-border">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    );
}

export default MainBanner;
