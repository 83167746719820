import React from 'react';
import { Link } from "gatsby";

import MapImg from "../../assets/images/map.png";

const FunFactsArea = () => {
    return (
        <div className="funfacts-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>Somos Solutoria sumamos soluciones a tu negocio</h2>
                    <div className="bar"></div>
                    <p>Generamos soluciones <b>simples</b> a problemas <b>complejos</b> apalancandonos en sistemas <b>CORE existentes</b>.</p>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>10+</h3>
                            <p>Proyectos actuales</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>100+</h3>
                            <p>Feedback</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>20+</h3>
                            <p>Trabajadores</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>10+</h3>
                            <p>Colaboradores</p>
                        </div>
                    </div>
                </div>

                <div className="contact-cta-box">
                    <h3>¿Tienes alguna pregunta sobre nosotros?</h3>
                    <p>No dudes en contactarnos !</p>

                    <Link to="/contact" className="btn btn-primary">
                        Contáctanos
                    </Link>
                </div>

                <div className="map-bg">
                    <img src={MapImg} alt="map" />
                </div>
            </div>
        </div>
    )
}

export default FunFactsArea;